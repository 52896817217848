
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator'

import { RequestPaging, throttle } from '@/utils/util'
import { getModels } from '@/api/dev'


@Component({
    components: {

    }
})
export default class Detail extends Vue {
    @Inject('visible') visible!: any
    $refs!: { table: any }
    @Prop() column!: any
    @Prop() types!: any
    @Prop({ default: 'single' }) type!: 'multiple' | 'single'
    @Prop() limit!: number
    @Prop({ default: false }) isSpec!: boolean
    @Prop({ default: () => { } }) params!: Record<any, any>
    // 是否展示虚拟商品
    @Prop({ default: false }) showVirtualGoods?: boolean
    searchData = {
        name: '',
        is_distribution: '',
        is_discount: ''
    }
    pager = new RequestPaging()
    selectedObj: any = {}
    activeTab = 'index0'
    models: any = []

    relationTypes = [
        {
            name: '一对一',
            value: 'has_one'
        },
        {
            name: '一对多',
            value: 'has_many'
        }
    ]


    formData = {
        name: '',
        model: '',
        type: '',
        local_key: '',
        foreign_key: ''
    }

    formRules = {
        name: [
            {
                required: true,
                message: '请输入关联名称'
            }
        ],
        type: [
            {
                required: true,
                message: '请选择关联类型'
            }
        ],
        model: [
            {
                required: true,
                message: '请选择关联模型'
            }
        ],
        local_key: [
            {
                required: true,
                message: '请选择关联健'
            }
        ],
        foreign_key: [
            {
                required: true,
                message: '请输入外键'
            }
        ]
    }

    // @Watch('visible', { deep: true, immediate: true })
    // visibleChange(val: any) {
    //     if (val.val) {
    //         this.getList()
    //     }
    // }

    getModelslist() {
        console.log("getModels")
        getModels().then(res => {
            // console.log(res)
            this.models = res
        })
    }

    created() {
        console.log("models")

        this.getModelslist()

    }

}
