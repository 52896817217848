
import { Component, Vue, Watch } from 'vue-property-decorator'
import { apiShopDetail, apiShopAdd, apiShopEdit, apiSetMealLists } from '@/api/shop'

import { debounce } from '@/utils/util'
import lsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
import LsDialog from '@/components/ls-dialog.vue'
import relationsAdd from "../components/relations-add.vue";
import dictValue from '@/components/dict-value/index.vue'
import codePreview from "../components/code-preview.vue";


import { generateEdit, tableDetail, getModels, generatePreview, generateCode } from '@/api/dev'

@Component({
  components: {
    LsDialog,
    relationsAdd,
    codePreview,
    // dictValue
  }
})
export default class ListsDetail extends Vue {
  $refs!: { relationDialog: any, formRef: any }

  /** S Data **/

  identity: number | null = null; // 当前编辑用户的身份ID

  setMealData: any = []
  code = []
  activeTab = 'index0'
  // 添加商城表单数据
  form: any = {
    name: '', // 名称
    super_admin: '', // 账号
    super_password: '', // 密码
    super_password_confirm: '', // 确认密码
    status: 1, // 商城状态 0-停止服务 1-开启服务
    contact: '', // 联系人
    contact_mobile: '', // 联系人手机号
    domain_alias: '', // 域名别名
    is_check_domain: 1, // 是否验证域名
    shop_url: '', // 当前域名
    set_meal_id: '', // 套餐ID
    expires_time: '', // 到期时间
    remark: '', // 备注
    shop_data: {}
  };


  formData: any = {
    id: '',
    table_name: '',
    table_comment: '',
    author: '',
    remark: '',
    template_type: 0,
    generate_type: 0,
    module_name: '',
    class_dir: '',
    class_comment: '',
    table_column: [] as any[],
    is_close_globalscope: 0,
    menu: {
      pid: 0,
      name: '',
      type: 0
    },
    generate_file: {
      controller: 1,
      model: 1,
      logic: 1,
      lists: 1,
    },
    tree: {
      tree_id: 0,
      tree_pid: 0,
      tree_name: 0
    },
    delete: {
      name: '',
      type: 0
    },
    relations: [] as any[]
  };
  editIndex: any = 0;
  activeName: any = 'base'
  showEdit: boolean = false
  

  // 表单校验
  rules = {
    name: [{ required: true, message: '请输入商城名称', trigger: 'blur' }],
    super_admin: [
      { required: true, message: '请输入商城名称', trigger: 'blur' }
    ],
    super_password: [
      { required: true, message: '请输入密码', trigger: 'blur' },
      {
        validator: (rule: object, value: string, callback: any) => {
          !value ? callback(new Error('请输入密码')) : callback()
        },
        trigger: 'blur'
      }
    ],
    super_password_confirm: [
      { required: true, message: '请再次输入密码', trigger: 'blur' },
      {
        validator: (rule: object, value: string, callback: any) => {
          if (this.form.password) {
            if (!value) callback(new Error('请再次输入密码'))
            if (value !== this.form.password) { callback(new Error('两次输入密码不一致!')) }
          }
          callback()
        },
        trigger: 'blur'
      }
    ],
    set_meal_id: [{ required: true, message: '请选择套餐', trigger: ['blur', 'change'] }],
    expires_time: [{ required: true, message: '请选择到期时间', trigger: ['blur', 'change'] }]
  };

  // add
  models: any = []

  relationTypes = [
    {
      name: '一对一',
      value: 'has_one'
    },
    {
      name: '一对多',
      value: 'has_many'
    }
  ]


  relationData = {
    name: '',
    model: '',
    type: '',
    local_key: '',
    foreign_key: ''
  }

  relationRules = {
    name: [
      {
        required: true,
        message: '请输入关联名称'
      }
    ],
    type: [
      {
        required: true,
        message: '请选择关联类型'
      }
    ],
    model: [
      {
        required: true,
        message: '请选择关联模型'
      }
    ],
    local_key: [
      {
        required: true,
        message: '请选择关联健'
      }
    ],
    foreign_key: [
      {
        required: true,
        message: '请输入外键'
      }
    ]
  }


  /** E Data **/

  /** S Methods **/
  // 点击表单提交
  onSubmit(formName: string) {
    console.log("onsubmit")
    // 验证表单格式是否正确
    // const refs = this.formRef as HTMLFormElement
    // formRef.validate((valid: boolean): any => {
    // if (!valid) return

    if (!this.identity) {
      this.handleShopAdd()
    } else {
      this.handleShopEdit()
    }
    // })
  }

  // 添加商城
  async handleShopAdd(): Promise<void> {
    await apiShopAdd({
      ...this.formData
    })
    setTimeout(() => this.$router.go(-1), 500)
  }

  // 编辑商城
  async handleShopEdit(): Promise<void> {
    await generateEdit({
      ...this.formData, id: this.identity
    })
    setTimeout(() => this.$router.go(-1), 500)
  }

  // 获取详情
  async getShopDetailFunc(): Promise<void> {
    console.log('获取详情', this.identity)
    const res: any = await tableDetail({
      id: this.identity as number
    })
    Object.keys(res).map((key) => {
      this.$set(this.formData, key, res[key])
    })
  }

  // 获取套餐列表
  async getSetMealLists(): Promise<void> {
    const { lists } = await apiSetMealLists({ status: 1 })
    this.setMealData = lists
  }
  // 模型
  async getModelslist(): Promise<void> {
    this.models = await getModels()
  }

  // 关联
  handleRelationConfirm() {
    console.log(this.relationData)
    this.formData.relations.push(this.relationData)
    this.$refs.relationDialog.close()
  }

  // 预览
  async handlePreview() {
    const data: any = await generatePreview({ id: this.identity })
    // console.log("data", data)
    this.code = data

    // this.$refs.codepreviewdialog?.onTrigger()
    // this.$refs.codepreviewdialog.close()
  }


  // 生成代码
  async handleGenerate(): Promise<void> {
    await generateCode({ id: this.identity });

  }

  /** E Methods **/

  /** S Life Cycle **/
  created() {
    const query: any = this.$route.query

    // 编辑模式：初始化数据
    this.identity = +query.id
    if (+query.id) {
      this.getShopDetailFunc()
    }

    this.onSubmit = debounce(this.onSubmit, 2000)
    this.getSetMealLists()
    this.getModelslist()
    this.handlePreview()
  }
  /** E Life Cycle **/
}
